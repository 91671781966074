import React from "react";
function HeaderLower() {
  return (
    <div className="header_lower">
      {/* // ? First 1 */}
      {/* City Furniture and Elite Rewards are proud supporters of the American
      Cancer Society. */}
      {/* // ? First 2 */}
      {/* City Furniture and Elite Rewards are proud supporters of the American
      Heart Association's Life Is Why campaign. */}
      {/* // ? First 3 */}
      {/* CITY Furniture and Elite Rewards are proud to support our partners who are dedicated to creating diverse, equal, and inclusive communities and opportunities. */}
      {/* // ? First 4 */}
      {/* CITY Furniture and Elite Rewards are proud to support Junior Achievement to prepare our youth for their successful
      futures. */}
      {/* // ? First 5 */}
      You dream. Together we give. CITY Furniture and Elite Rewards are proud to
      support organizations making a difference in our local communities.
    </div>
  );
}
export default HeaderLower;
