import React from "react";
import { useState, useEffect } from "react";
import "./style.css";

import Footer2 from "./Footer2";
// import UserformSurvey from "./mattress_questionnare";
import { Navigate, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import { Helmet } from "react-helmet";
import secureLocalStorage from "react-secure-storage";

// import axios from "axios";

function Index(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Register";
  const navigate = useNavigate();

  const submitForm = (e) => {
    e.preventDefault();
    localStorage.setItem("return_page", "true");
    navigate("/register/registration-form");
  };
  useEffect(() => {
    // if (localStorage.getItem("return_page") === "true") {
    //   window.location.href = "/register/registration-form";
    // } else {
    // }
    localStorage.setItem("return_page", "false");
  }, []);

  const visited = () => {
    secureLocalStorage.setItem("visited", true);
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        <div className="white-box">
          <ul className="points">
            <form
              method="post"
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submitForm}
            >
              <h2 className="register_lower" style={{ textAlign: "center" }}>
                {/* // ? First 1 */}
                {/* City Furniture and Elite Rewards are proud supporters of the American
      Cancer Society. */}
                {/* // ? First 2 */}
                {/* City Furniture and Elite Rewards are proud supporters of the
                American Heart Association's Life Is Why campaign. */}
                {/* // ? First 3 */}
                {/* CITY Furniture and Elite Rewards are proud to support our partners who are dedicated to creating diverse, equal, and inclusive communities and opportunities. */}
                {/* // ? First 4 */}
                {/* CITY Furniture and Elite Rewards are proud to support Junior Achievement to prepare our youth for their successful
      futures. */}
                {/* // ? First 5 */}
                You dream. Together we give. CITY Furniture and Elite Rewards
                are proud to support organizations making a difference in our
                local communities.
              </h2>
              <div className="row">
                <div className="col-md-12" id="text">
                  <p className="register-p">
                    {/* // ? First 1 */}
                    {/* Would you be interested in giving 8-10 minutes of your time
                    to improve your sleep and be part of a movement to help end
                    breast cancer? You'll get a $10 gift card when you complete
                    a mattress comfort test rest, and we'll also give $10 to the
                    American Cancer Society. Just a few moments of your time can
                    help support breast cancer research, including access to
                    screenings and transportation to treatments. */}
                    {/* 4-4-2023 Changes */}
                    {/* // ? First 2 */}
                    {/* Would you be interested in giving 8-10 minutes to improve
                    your sleep and also help support our fight to end heart
                    disease? We are offering a 25$ gift card to do a test rest
                    in our mattress gallery and also will matching that gift
                    card value as a donation to American Heart Association. Just
                    a few moments of your time can help improve your sleep and
                    help save a life! */}
                    {/* // ? First 3 */}
                    {/* You Get $10, We Give $10. Would you be interested in giving
                    5-10 minutes of your time to improve your sleep and make an
                    impact in our community? We are offering you a $10 reward
                    card to do a sleep survey in our mattress gallery and CITY
                    Furniture will make a $10 donation to non-profit partners
                    who are promoting acceptance and inclusion, empowering small
                    businesses, and advancing social and economic equality. Just
                    a few moments of your time can help improve your sleep and
                    impact change in our community. */}
                    {/* // ? First 4 */}
                    {/* You Get $10, We Give $10. Would you be interested in giving
                    5-10 minutes of your time to improve your sleep and make an
                    impact in our community? We are offering you a $10 reward
                    card to do a sleep survey in our mattress gallery and CITY
                    Furniture will make a $10 donation to Junior Achievement, to
                    inspire the dreams of our next generation. Just a few
                    moments of your time can help improve your sleep and impact
                    change in our community. */}
                    {/* You Get $10, We Give $10. Would you be interested in giving
                    5-10 minutes of your time to improve your sleep and make an
                    impact in our community? We are offering you a $10 reward
                    card to do a sleep survey in our mattress gallery and CITY
                    Furniture will make a $10 donation back to the community.
                    Through this program, $1.5 million was donated in 2023,
                    impacting multiple charities from American Cancer Society to
                    Junior Achievement. This year, the goal is to raise more,
                    increase partner reach, and help as many people as possible.
                    Together. <br /> Just a few moments of your time can help
                    improve your sleep and impact families in our community. */}
                    You Get $10, We Give $10. Would you be interested in giving
                    10 minutes of your time to improve your sleep and make an
                    impact in our community? We are offering you a $10 reward
                    card to do a sleep survey in our mattress gallery and CITY
                    Furniture will make a $10 donation back to the community.
                    Through this program, $1.5 million was donated in 2023,
                    impacting multiple charities from American Cancer Society to
                    Junior Achievement. This year, the goal is to raise more,
                    increase partner reach, and help as many people as possible.
                    Together. <br /> Just a few moments of your time can help
                    improve your sleep and impact families in our community.
                  </p>
                </div>
              </div>
              <br />
              <input
                // onClick="myTimeout()"
                type="submit"
                name="submit"
                id="submit"
                value="CONTINUE"
                className="sub-btn button"
                onClick={visited}
              ></input>
            </form>
          </ul>
        </div>
      </div>

      <Footer2 />
    </>
  );
}
export default Index;
